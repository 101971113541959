import { Shoe } from "./interfaces";

export const shoes: Shoe[] = [
  {
    id:1,
    name:"H209A4H00010M2439999_Trailgrip_Apres_Snow_Boots",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4H00010M2439999_Trailgrip_Apres_Snow_Boots/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4H00010M2439999_Trailgrip_Apres_Snow_Boots/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4H00010M2439999_Trailgrip_Apres_Snow_Boots/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:1,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:2,
    name:"H209A4M00070M2055034_Lite_Runner_Low_Top_trainers",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00070M2055034_Lite_Runner_Low_Top_trainers/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00070M2055034_Lite_Runner_Low_Top_trainers/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00070M2055034_Lite_Runner_Low_Top_trainers/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:2,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:3,
    name:"H209A4M00070M2055P07_Lite_Runner_Low_Top_Trainers_Beige",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00070M2055P07_Lite_Runner_Low_Top_Trainers_Beige/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00070M2055P07_Lite_Runner_Low_Top_Trainers_Beige/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00070M2055P07_Lite_Runner_Low_Top_Trainers_Beige/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:3,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:4,
    name:"H209A4M00080M2056P04_Monte_Runner_Trainers",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00080M2056P04_Monte_Runner_Trainers/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00080M2056P04_Monte_Runner_Trainers/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00080M2056P04_Monte_Runner_Trainers/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:4,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:5,
    name:"H209A4M00100M2058014_Trailgrip_GTX_Low_Top_Trainers_Off_White",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00100M2058014_Trailgrip_GTX_Low_Top_Trainers_Off_White/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00100M2058014_Trailgrip_GTX_Low_Top_Trainers_Off_White/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00100M2058014_Trailgrip_GTX_Low_Top_Trainers_Off_White/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:5,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:6,
    name:"H209A4M00100M2058P99_Trailgrip_GTX_Low_Top_Trainers_Taupe_Black",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00100M2058P99_Trailgrip_GTX_Low_Top_Trainers_Taupe_Black/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00100M2058P99_Trailgrip_GTX_Low_Top_Trainers_Taupe_Black/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00100M2058P99_Trailgrip_GTX_Low_Top_Trainers_Taupe_Black/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:6,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:7,
    name:"H209A4M00110M2059999_Trail_Grip_Apres_trainers",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00110M2059999_Trail_Grip_Apres_trainers/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00110M2059999_Trail_Grip_Apres_trainers/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00110M2059999_Trail_Grip_Apres_trainers/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:7,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:8,
    name:"H209A4M00200M2200999_Trail_Grip_Gtx_high_top_trainers",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00200M2200999_Trail_Grip_Gtx_high_top_trainers/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00200M2200999_Trail_Grip_Gtx_high_top_trainers/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00200M2200999_Trail_Grip_Gtx_high_top_trainers/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:8,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:9,
    name:"H209A4M00200M2437P19_Trailgrip_Gtx_High_Top_Trainers",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00200M2437P19_Trailgrip_Gtx_High_Top_Trainers/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00200M2437P19_Trailgrip_Gtx_High_Top_Trainers/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209A4M00200M2437P19_Trailgrip_Gtx_High_Top_Trainers/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:9,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:10,
    name:"H209B4M00130M2055P49_Lite_Runner_Low_Top_Trainers",
    brand:"Moncler",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209B4M00130M2055P49_Lite_Runner_Low_Top_Trainers/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209B4M00130M2055P49_Lite_Runner_Low_Top_Trainers/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/vyk_client/vyk_moncler_zb92pq8/H209B4M00130M2055P49_Lite_Runner_Low_Top_Trainers/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:10,
    date_created:1590499834,
    last_updated:1590499834
   },
];
